<template>
    <v-app>
        <v-app-bar app clipped-left dense class="elevation-3" color="primary">
            <v-app-bar-nav-icon class="mx-1 white--text" href="/"><v-icon>mdi-home</v-icon></v-app-bar-nav-icon>
            <!-- <img src="/unifylogo.svg" class="pa-0 mr-3" height="15" @click="showChangelog = true" style="cursor: pointer" /> -->
            <v-toolbar-title class="text-h6 white--text pa-0 mr-3">Status Monitor</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn class="white--text mr-1" icon @click="portalDrawer = !portalDrawer">
                <v-icon>mdi-dots-grid</v-icon>
            </v-btn>

            <v-menu bottom left offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="white--text mr-1" icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list dense nav>
                    <v-list-item>
                        <!-- <v-list-item-content>
                            <v-list-item-title class="font-weight-bold">
                                {{ this.$auth.userProfile.name }}
                            </v-list-item-title>
                        </v-list-item-content> -->
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item link @click="$vuetify.theme.dark = !$vuetify.theme.dark">
                        <v-list-item-icon class="mr-3">
                            <v-icon>mdi-theme-light-dark</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $vuetify.theme.dark ? 'Light Mode' : 'Dark Mode' }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="$auth.signOut()">
                        <v-list-item-icon class="mr-3">
                            <v-icon>mdi-logout-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Sign Out</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-navigation-drawer app floating permanent clipped class="elevation-3" v-model="drawer" :mini-variant="$vuetify.breakpoint.smAndDown || collapseNavigation">
            <v-list dense nav>
                <v-list-item-group color="primary">
                    <v-list-item link exact :to="{ name: 'Status' }">
                        <v-list-item-icon class="mr-3"><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Client</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item link exact :to="{ name: 'ApiStatus' }">
                        <v-list-item-icon class="mr-3"><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Api</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -->
                </v-list-item-group>
            </v-list>
            <template v-slot:append>
                <v-list dense nav>
                    <v-list-item v-if="!$vuetify.breakpoint.smAndDown" @click="collapseNavigation = !collapseNavigation">
                        <v-list-item-icon class="mr-3">
                            <v-icon>{{ collapseNavigation ? 'mdi-arrow-expand' : 'mdi-arrow-collapse' }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content><v-list-item-title>Collapse</v-list-item-title></v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>
        <router-view class="px-10 py-10" />
        <v-navigation-drawer absolute temporary right v-model="portalDrawer">
            <v-list dense>
                <v-list-item>
                    <v-list-item-content>Services</v-list-item-content>
                </v-list-item>
                <v-list-item :href="`https://portal.uc3s.unify.ws/`">
                    <v-list-item-avatar rounded color="#0096c7">
                        <v-icon color="white" large>mdi-alpha-u</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>UC3S</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item href="https://portal.lobby.unify.ws">
                    <v-list-item-avatar rounded color="#FF5722">
                        <v-icon color="white" large>mdi-alpha-p</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>PICS</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item href="https://dashboard.analytics.unify.ws">
                    <v-list-item-avatar rounded color="#839496">
                        <v-icon color="white" large>mdi-alpha-a</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>Analytics</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-app>
</template>

<script>
export default {
    name: "BuiLd",


    data() {
        return {
            drawer: null,
            portalDrawer: null,
            collapseNavigation: false,
        }
    },
}
</script>

<style>

</style>
