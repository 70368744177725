<template>
    <v-container class="removeApp">
        <v-card>
            <v-card-title>
                Remove Client
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="clientToRemove"
                            :items="this.clients"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col><button @click="removeClient">Remove Client</button></v-col>
                    <v-col><button @click="$emit('close')">Close</button></v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-overlay
            v-model="confirmationOverlay"
        >
            <v-card class="confirmation">
                <v-card-title>
                    Confirm Remove: {{ clientToRemove }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <button @click="confirm">Confirm</button>
                        </v-col>
                        <v-col>
                            <button @click="cancelConfirmation">Cancel</button>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-overlay>
    </v-container>
</template>

<script>
export default {
    name: "RemoveApplication",

    emits: ["close"],

    data() {
        return {
            clients: [],
            clientToRemove: "",
            confirmationOverlay: false
        }
    },

    mounted() {
        this.getAllClients()
    },

    methods: {
        async getAllClients() {
            let response = await this.$applicationApi.applicationRoutes.getApplications()
            this.clients = response.data
            console.log(this.clients)
        },

        removeClient() {
            if (this.clientToRemove != "") {
                this.confirmationOverlay = true
            }
        },

        confirm() {
            this.$applicationApi.applicationRoutes.remove(this.clientToRemove.substring(8))
            this.$emit("close")
        },

        cancelConfirmation() {
            this.clientToRemove = ""
            this.confirmationOverlay = false
        },
    }
}
</script>

<style>

.removeApp {
    min-width: 40vw;
}

.confirmation {
    min-width: 40vw;
}

</style>
