export default class statusRoutes {
    constructor(apiService) {
        this.apiService = apiService
    }

    getAll() {
        return this.apiService.get(`v1/status`)
    }

    get(appUrl) {
        return this.apiService.post(`v1/status`, appUrl, {headers: {'accept': 'application/json', 'Content-Type': 'application/json'} })
    }
}
