<template>
    <v-container class="newApp">
        <v-card>
            <v-card-title>
                New Client
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="newClientUrl"
                            label="New Client Url"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col><button @click="addClient">Add Client</button></v-col>
                    <v-col><button @click="$emit('close')">Close</button></v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "NewApplication",

    emits: ["close"],

    data() {
        return {
            newClientUrl: "https://"
        }
    },

    methods: {
        addClient() {
            this.$applicationApi.applicationRoutes.new(this.newClientUrl)
            this.$emit("close")
        }
    }
}
</script>

<style>

.newApp {
    min-width: 40vw;
}

</style>
