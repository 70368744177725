import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import auth from '@/auth'
import applicationApi from '@/services/applicationApi'

Vue.config.productionTip = false

const api = new applicationApi()
api.useAuth(auth)
Vue.prototype.$applicationApi = api

auth.useRouter(router)
Vue.prototype.$auth = auth

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
