<template>
    <main>
        <v-row>
            <v-col>
                <h1>Status</h1>
            </v-col>
            <v-spacer/>
            <v-col class="newClient">
                <v-btn @click="newClientCard = !newClientCard">New Client</v-btn>
            </v-col>
            <v-col class="removeClient">
                <v-btn @click="removeClientCard = !removeClientCard">Remove Client</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ClientStatuses />
            </v-col>
        </v-row>
        <v-overlay
            v-model="newClientCard"
            contained
        >
            <v-card>
                <NewApplication @close="newClientCard = !newClientCard"/>
            </v-card>
        </v-overlay>
        <v-overlay
            v-model="removeClientCard"
            contained
        >
            <v-card>
                <RemoveApplication @close="removeClientCard = !removeClientCard"/>
            </v-card>
        </v-overlay>
    </main>
</template>

<script>
import ClientStatuses from '@/components/ClientStatuses.vue'
import NewApplication from '@/components/NewApplication.vue'
import RemoveApplication from '@/components/RemoveApplication.vue'

export default {
    name: "StaTus",

    components: {
        ClientStatuses,
        NewApplication,
        RemoveApplication
    },

    data() {
        return {
            newClientCard: false,
            removeClientCard: false
        }
    },
}
</script>

<style>

.newClient {
    display: flex;
    justify-content: flex-end;
}

.removeClient {
    display: flex;
    justify-content: flex-end;
}

</style>
