import { createAuth } from '@/plugins/oidc'

const config = {
    authority: 'https://whodis.unify.fun',
    client_id: 'status-monitor-staging',
    scope: 'openid profile status-api-staging',
    redirect_uri: `${location.protocol}//${location.host}/oidc/return`,
    silent_redirect_uri: `${location.protocol}//${location.host}/oidc/renew`,
    post_logout_redirect_uri: `${location.protocol}//${location.host}`
}

const auth = createAuth(config)

export default auth
