<template>
    <main>
        <h1>Status Monitor</h1>
    </main>
</template>

<script>
export default {
    name: "HomE",

}
</script>

<style>

</style>
