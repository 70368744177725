export default class applicationRoutes {
    constructor(apiService) {
        this.apiService = apiService
    }

    getApplications() {
        return this.apiService.get(`v1/application`)
    }

    new(appUrl) {
        return this.apiService.post(`v1/application`, appUrl, {headers: {'accept': 'application/json', 'Content-Type': 'application/json'} })
    }

    remove(appUrl) {
        return this.apiService.delete(`v1/application/${appUrl}`)
    }
}